<template>
  <div class="col-12">
    <div class="card card--profile-calendar">
      <div
        v-b-toggle="`collapseCalendar`"
        class="card-header"
        @click="recalculateSize()"
      >
        <h3 class="cart-title">
          <a data-action="collapse"><i
            data-feather="calendar"
            class="w-icon-24"
          /> Calendar & alarms </a>
        </h3>
        <div class="heading-elements">
          <ul class="list-inline mb-0 disp-in ms-2">
            <li>
              <a data-action="collapse"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              ><polyline points="6 9 12 15 18 9" /></svg></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseCalendar`"
        :visible="fields.find(e => e.name == 'Calendar').visibility"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6 card--profile-alerts-calendar">
              <!-- Full calendar start -->
              <section>
                <div class="app-calendar overflow-hidden border">
                  <div class="row g-0">

                    <!-- Calendar -->
                    <div class="col position-relative">
                      <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                          <FullCalendar
                            ref="calendar"
                            :options="calendarOptions"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /Calendar -->
                    <div class="body-content-overlay" />
                  </div>
                </div>
              </section>
            <!-- Full calendar end -->
            </div>
            <div class="col-sm-6 card--profile-alerts-alarms">
              <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                  <div class="row breadcrumbs-top">
                    <div class="col-12">
                    <!-- <h3 class="content-header-title float-start mb-0">
                      <i
                        data-feather="bell"
                        class="w-icon-24"
                      />
                      Create Alarms
                    </h3> -->
                    </div>
                  </div>
                </div>
                <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
                  <div class="breadcrumb-right">
                    <a
                      class="btn btn-icon btn-secondary"
                      @click="createAlert({})"
                    ><i data-feather="plus" /></a>
                  </div>
                </div>
              </div>
              <!-- <hr> -->
              <template v-if="loading">
                <div
                  class="loading custom-loading"
                >
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </template>
              <template v-else>
                <div
                  v-for="alert in realAlerts"
                  :key="alert.id"
                  class="profile__important__notification profile__important__notification--list"
                >
                  <div class="row">
                    <div class="col-9">
                      <span class="text-info"><i data-feather="bell" /></span> <strong>{{ alert.title }}</strong>: <span
                        class="no-linebreak"
                        v-html="alert.message"
                      />
                      <p class="text-muted">
                        <small>{{ alert.date }} {{ alert.end_date_format ? '- ' + alert.end_date_format : '' }}</small>
                      </p>
                    </div>
                    <div class="col-3 text-end">
                      <span>
                        <a
                          class="btn bg-light-secondary me-1"
                          @click="$emit('showAlert', alert)"
                        >
                          <i data-feather="link" />
                        </a>
                        <a
                          class="btn bg-light-secondary"
                          @click="archive(alert.id)"
                        >
                          <i data-feather="archive" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-collapse>
      <CreateAlert>
        <template #title>
          Create {{ !alert.created ? 'alarm' : 'event' }}
        </template>
        <template #create-alert>
          <OffcanvaBody />
        </template>
      </CreateAlert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import CreateAlert from '@/views/back/partials/offcanvas/CreateAlert.vue'
import 'vue2-datepicker/index.css'
import OffcanvaBody from '@/views/back/admin/alerts/components/OffcanvaBody.vue'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    FullCalendar,
    CreateAlert,
    OffcanvaBody,
    BCollapse,
  },
  data() {
    return {
      disabledBefore: new Date(),
      alert: {},
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.createAlert,
        eventClick: this.showAlert,
        datesSet: this.loadAlerts,
        eventClassNames: this.eventClassNamesCallback,
        events: [],
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
      },
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      alerts: 'alerts/alerts',
      loading: 'alerts/loading',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
    }),
    realAlerts() {
      return this.alerts.filter(e => e.type.id === 2)
    },
  },
  watch: {
    alerts() {
      this.calendarOptions.events = this.alerts.map(alert => {
        const data = {
          title: alert.title,
          date: alert.unformatted_date,
          end: alert.end_date,
          alert,
        }

        return data
      })
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    eventClassNamesCallback({ event }) {
      if (event.extendedProps.alert.type.id === 1) {
        if (event.extendedProps.alert.done) {
          return 'done' // Class for showing it green
        }

        return 'pending' // Class for showing it red
      }

      if (event.extendedProps.alert.type.id === 2) {
        return 'event-type-2' // Class for type_id 2
      }

      return null
    },
    getDates() {
      const calendarApi = this.$refs.calendar.getApi()
      return {
        begin_date: calendarApi.view.activeStart,
        end_date: calendarApi.view.activeEnd,
        user_id: this.$route.params.id,
      }
    },
    async submit() {
      this.sending = true
      if (this.$route.params.id || this.$route.query.prefilterUser) {
        this.alert.users = [{ id: (this.$route.params.id ?? this.$route.query.prefilterUser) }]
      }
      await this.$store.dispatch('alerts/create', this.alert)
      const dates = this.getDates()
      this.$store.dispatch('modals/toggleCreateItem', false)
      this.alert = {}
      await this.$store.dispatch('alerts/fetch', dates)
      this.sending = false
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    async archive(id) {
      await this.$store.dispatch('alerts/archive', id)

      this.loading = true
      await this.$store.dispatch('alerts/fetch', { ...this.getDates(), archived: false })
      this.loading = false
    },
    createAlert(arg) {
      if (!arg.dateStr) {
        this.$store.dispatch('modals/toggleCreateItem', true)
        this.alert = {
          created: !!arg.dateStr, begin_date: arg.dateStr, end_date: arg.dateStr, type: { label: 'Alarm', value: 2 },
        }
      } else {
        const clickedDate = this.getDateWithoutTime(arg.date)
        const nowDate = this.getDateWithoutTime(new Date())
        if (clickedDate >= nowDate) {
          this.$store.dispatch('modals/toggleCreateItem', true)
          this.alert = { created: !!arg.dateStr, begin_date: `${arg.dateStr} 00:00:00`, end_date: arg.dateStr }
        } else {
          this.$toastr.error('Please, select another day', 'Can\'t create notifications in the past')
        }
      }
    },
    disabledRange(date) {
      return date <= new Date()
    },
    getDateWithoutTime(dt) {
      dt.setHours(0, 0, 0, 0)
      return dt
    },
    showAlert(arg) {
      const { alert } = arg.event.extendedProps
      this.$emit('showAlert', alert)
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
        user_id: this.loggedUser.id,
      }

      this.alert.file = fileData
    },
    async loadAlerts(arg) {
      const dates = {
        begin_date: arg.startStr,
        end_date: arg.endStr,
        show_calendar: true,
        per_page: 500,
        archived: false,
        researcher: { id: this.$route.params.id ?? this.$route.query.prefilterUser },
      }

      await this.$store.dispatch('alerts/fetch', dates)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    recalculateSize() {
      setTimeout(() => {
        this.$refs.calendar.getApi().updateSize()
      }, 500)
    },
  },
}
</script>
